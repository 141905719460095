//尽调情况
<template>
  <div class="investigationSituation">
    <div class="add">
      <div class="addbutton" @click="addInfo">
        <i class="el-icon-plus"></i>
      </div>
    </div>
    <base-table
      :columns="investigationSituationColumn"
      :tableAttrs="{
        data: tableData,
        stripe: true,
        'min-height':'200'
      }"
      :isCaculateHeight="false"
      :showPage="false"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
       <template slot="visitDateHeader">
        <font style="color: #ee1c1c">*</font>拜访日期
      </template>
       <template slot="visitAddressHeader">
        <font style="color: #ee1c1c">*</font>拜访地址
      </template>
      <template slot="visitCompanyHeader">
        <font style="color: #ee1c1c">*</font>拜访企业
      </template>
      <template slot="visitPersonnelListHeader">
        <font style="color: #ee1c1c">*</font>拜访人员
      </template>
      <template slot="finFileInfosHeader">
        <font style="color: #ee1c1c">*</font>现场合照
      </template>
      <!-- 拜访日期 -->
      <template slot="visitDate" slot-scope="scope">
        <el-date-picker
          v-model="scope.row.visitDate"
          type="date"
          value-format="timestamp"
          placeholder="选择日期"
          :pickerOptions="startDatePicker"
        >
        </el-date-picker>
      </template>
      <!-- 拜访地址 -->
      <template slot="visitAddress" slot-scope="scope">
        <el-input
          v-model="scope.row.visitAddress"
          placeholder="请输入拜访地址"
          maxlength="200"
        ></el-input>
      </template>
      <!-- 拜访企业 -->
      <template slot="visitCompany" slot-scope="scope">
        <new-base-select
          v-model="scope.row.visitCompany"
          :options="gysOptions"
          :selectedField="['keyId', 'enterpriseName']"
          :filterable="true"
          @change="changeGys(scope.row.visitCompany, scope.$index)"
        ></new-base-select>
      </template>
      <!-- 拜访人员 -->
      <template slot="visitPersonnelList" slot-scope="scope">
        <base-select
          v-model="scope.row.visitPersonnelList"
          :options="scope.row.visitPersonnelListOptions"
          :clearable="true"
          :multiple="true"
          :disabled="!scope.row.visitCompany"
          :selectedField="['keyId', 'personnerName']"
          @change="changeVisitPersonnelList(scope.row.visitPersonnelList, scope.$index)"
        ></base-select>
      </template>
      <!-- 现场合照 -->
      <template slot="finFileInfos" slot-scope="scope">
        <!-- 文件列按钮组 -->
        <file-button-group
          v-for="(item, index) in scope.row.finFileInfos"
          :key="index"
          :item="item"
          :index="index"
          :scope="scope"
          :isSign="scope.row.isSign"
          @previewShow="openPreview"
          @Upload="Upload"
          @deleteTemplate="deleteTemplate"
          @dowload='dowloadFile'
        />
      </template>
      <template slot="action" slot-scope="scope">
         <icon-button
          @click="appendFile(scope.row, scope.$index)"
          content="追加文件"
          icon="iconfont iconzhuijia"
        />
        <icon-button
          content="删除"
          icon="iconfont iconshanchu1"
          @click="del(scope.row, scope.$index)"
        />
      </template>
    </base-table>
    <!-- 文件预览 -->
         <pre-view :fileId="fileData.fileId" :isOpen='true' :fileType="fileData.fileFileSuffix" :count="fileCount" />

  </div>
</template>
<script>
import PreView from '@/components/pre-view/pre-view.vue'

import baseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { investigationSituationTable } from '../utils/financing-info-config'
// import { dueDiligenceApi } from '@/api/businessApi'
// fileListApi
import { supplierApi } from '@/api/companyApi'
import { contactApi } from '@/api/contactApi'
import { fileAPi, downAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'
import FileButtonGroup from '../components/file-button-group.vue'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import NewBaseSelect from '@/components/common/base-select/new-base-select.vue'
export default {
  props: {
    investigationSituationData: Array
  },
  components: { baseTable, IconButton, BaseSelect, FileButtonGroup, PreView, NewBaseSelect },
  data () {
    return {
      personnelList: [], // 人员列表下拉
      gysOptions: [], // 供应商列表
      businessId: '', // id
      startDatePicker: {}, // 起始日期
      fileData: { fileId: '', fileFileSuffix: '' },
      fileCount: 0
    }
  },
  watch: {
  },

  computed: {
    investigationSituationColumn () {
      return investigationSituationTable(this)
    },
    tableData: {
      get () {
        return this.investigationSituationData
      },
      set (val) {
        this.$emit('changeInvestigationSituationData', val)
      }
    }
  },
  created () {
    this.businessId = this.$route.query.businessId
    this.startDatePicker = {
      disabledDate (time) {
        // const disabledTime = new Date('1999-12-31')
        return time.getTime() < new Date('1999-12-31') || time.getTime() > new Date('2100-12-31')
      }
    }
  },
  mounted () {
    this.getGysList()
    // 监听一次，第一次进入的table 执行返显
    const unwatch = this.$watch('tableData', function (newValue, oldValue) {
      console.log(newValue, oldValue, 'tableData')
      if (newValue) {
        this.investigationSituationData.forEach((item, index) => {
          if (item.visitPersonnel) {
            // 将传入的数据在转化成数组
            this.$set(this.investigationSituationData[index], 'visitPersonnelList', item.visitPersonnel.split(','))
            // 每一行增加对应的人员列表
            this.$set(this.investigationSituationData[index], 'visitPersonnelListOptions', this.getGysPersonnelList(item.visitCompany))
          }
        })
      }
      unwatch()
    })
  },

  methods: {

    // 获取供应商数据
    getGysList () {
      supplierApi.getList().then((res) => {
        if (res.success) {
          this.gysOptions = res.data
        }
      })
    },
    // 人员下拉
    getGysPersonnelList (data) {
      const arr = []
      contactApi.getGysPersonnelList({ enterpriseId: data }).then(res => {
        if (res.data) {
          res.data.forEach(item => {
            arr.push(item)
          })
        }
      })
      return arr
    },
    // 选择供应商
    changeGys (data, index) {
      if (data) {
        this.$set(this.investigationSituationData[index], 'visitPersonnelListOptions', this.getGysPersonnelList(data))
      } else {
        this.$set(this.investigationSituationData[index], 'visitPersonnel', '')
        this.$set(this.investigationSituationData[index], 'visitPersonnelList', [])
      }
    },
    // 选择人员
    changeVisitPersonnelList (data, index) {
      this.$set(this.tableData[index], 'visitPersonnel', data.toString())
    },
    addInfo () {
      this.tableData.push({ add: true, businessId: this.$route.query.businessId, visitPersonnelListOptions: [] })
    },
    // 删除业务数据
    del (data, index) {
      this.tableData.splice(index, 1)
    },
    // 追加文件
    appendFile (row, index) {
      console.log(row, 'row')
      if (!row.finFileInfos) {
        this.$set(row, 'finFileInfos', [])
      }
      if (row.finFileInfos.length > 10) {
        return this.warning('最多上传10份文件')
      }
      const data = row.finFileInfos.every(item => item.fileId !== '0')
      if (!data) {
        return this.warning('请先上传文件')
      }
      row.finFileInfos.push({ fileId: '0', fileName: '', addFile: true })
    },
    // 预览
    openPreview (data) {
      console.log(data, '++++')
      this.fileData.fileId = data.fileId
      this.fileData.fileFileSuffix = data.fileName.substr(data.fileName.lastIndexOf('.') + 1, 4)
      this.fileCount++
    },
    // 文件上传
    async Upload (param, row, index, childIndex) {
      // const arr = param.file.name.split('.')
      // const suffix = arr[arr.length - 1]
      // const lite = ['doc', 'docx', 'xlsx', 'xls', 'pdf', 'png', 'jpg', 'jpeg', 'bmp']
      // const flag = lite.some((val) => {
      //   return suffix === val
      // })
      // if (!flag) {
      //   this.warning('请上传doc,docx,xlsx,xls,pdf,png,jpg,jpeg,bmp格式文件')
      //   return
      // }
      const res = await this.UploadFile(param)
      if (res) {
        this.$set(this.tableData[index].finFileInfos[childIndex], 'fileName', res.fileName)
        this.$set(this.tableData[index].finFileInfos[childIndex], 'fileId', res.keyId)
      }
    },
    // 上传方法
    async UploadFile (param) {
      const EmojiReg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
      if (EmojiReg.test(param.file.name)) {
        this.warning('文件名不能存在Emoji')
        return false
      }
      const maxSize = 1024 * 10
      const fileSize = Math.round(param.file.size / 1024)
      if (fileSize - maxSize >= 0) {
        return this.warning('文件需小于10M')
      }
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      const res = await fileAPi.file(formData).then((res) => {
        if (res.data) {
          return res.data
        }
      })
      return res
    },
    // 删除文件
    deleteTemplate (row, index, i) {
      console.log(row, i, 'del')
      this.tableData[index].finFileInfos.splice(i, 1)

      // 没有上传文件的直接删除
      // if (row.fileId === '0') {
      //   this.accessoriesData[index].finFileInfos.splice(i, 1)
      //   return
      // }
      // this.$confirm('是否删除文件?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // })
      //   .then(() => {
      //     // 调用接口删除
      //     fileListApi.deleteFile([row.finFileInfos[i].keyId]).then(res => {
      //       if (res.data) {
      //         this.success('删除成功')
      //         this.accessoriesData[index].finFileInfos.splice(i, 1)
      //         this.getTableData()
      //       } else {
      //         this.error('删除失败')
      //       }
      //     })
      //   })
      //   .catch(() => {})
    },
    // 重新上传文件
    updataFile (file) {
      const files = file.target.files[0]
      const formData = new FormData()
      formData.append('file', files)
      this.api
        .UploadLmplementFile(formData)
        .then((res) => {
          this.arr.forEach((item) => {
            if (res.data.fileName === item.documentName) {
              item.fileId = res.data
            }
          })
          this.$message.success('文件上传成功')
        })
        .catch((res) => {
          this.$message.error('文件上传失败')
        })
    },
    // 文件下载
    dowloadFile (data) {
      const fileId = data.currentFileId || data.fileId
      if (fileId) {
        const params = { keyId: fileId }
        downAPi
          .download(params)
          .then((res) => {
            downFile(res)
          })
          .catch((e) => {
            this.error(e)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.investigationSituation {
  padding: 10px;
}
.add {
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .addbutton {
    margin-left: 20px;
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      background-color: #6996FF;
    }
  }
}
</style>
