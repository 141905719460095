<template>
  <!-- 企业担保模块 -->
  <div class="enterpriseGuarantee">
    <!-- 企业担保 -->
    <div class="head">

      <TitleCenter :rules="false" name="企业担保" />
      <div class="addbutton" @click="addEnterprise">
        <i class="el-icon-plus"></i>
      </div>
    </div>
      <!-- 表格录入数据 -->
      <base-table
        :columns="enterpriseGuarantee"
        :tableAttrs="{
          data: enterpriseData,
          stripe: true,
        }"
        :isCaculateHeight="false"
        :showPage="false"
      >
        <template slot="code" slot-scope="scope">{{
          scope.$index + 1
        }}</template>
        <template slot="action" slot-scope="scope">
          <IconButton
            @click="removeEnterprise(scope.row,scope.$index)"
            content="删除"
            icon="iconfont iconshanchu1"
          ></IconButton>
        </template>
      </base-table>
    <!-- 选择企业担保数据 -->
    <add-enterprise
      :visible="enterpriseState"
      @emitVisible="enterpriseState = false"
      :enterpriseData="enterpriseData"
    ></add-enterprise>
  </div>
</template>
<script>
import TitleCenter from '@/pages/business/components/title-center.vue'
import baseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { enterpriseTable } from '../utils/financing-info-config'
import AddEnterprise from './add-enterprise.vue'
// import { contactApi } from '@/api/contactApi'
import { financeAdmittanceApi } from '@/api/financeAdmittanceApi'

export default {
  components: { baseTable, IconButton, AddEnterprise, TitleCenter },
  name: 'enterpriseGuarantee',
  props: {
    // enterpriseData: Array
  },
  data () {
    return {
      businessId: '',
      enterpriseData: [],
      enterpriseState: false
    }
  },
  created () {
    this.businessId = this.$route.query.businessId
  },
  computed: {
    enterpriseGuarantee () {
      return enterpriseTable(this)
    }
  },
  mounted () {
    if (this.businessId) {
      this.getEnterpriseData()
    }
  },
  methods: {
    getEnterpriseData () {
      financeAdmittanceApi.getEnterpriseList({ businessId: this.businessId }).then(res => {
        console.log(res.data, '企业数据')
        this.enterpriseData = res.data ? res.data : []
      })
    },
    // 新增企业担保
    addEnterprise () {
      this.enterpriseState = true
    },
    // 选择之后设置参数
    changeType (data, arr, index) {
      console.log('1')
      this.addEnterpriseData(this.enterpriseData, 'change')
    },
    // 删除企业担保数据
    removeEnterprise (data, index) {
      financeAdmittanceApi.delByEnterprise([data.keyId]).then(res => {
        if (res.data) {
          this.enterpriseData.splice(index, 1)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.enterpriseGuarantee{
  padding: 10px;
}
.head {
  display: flex;
  justify-content: space-between;
  height: 30px;
  margin-bottom: 8px;
  // 页面按钮
  .addbutton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      background-color: #6996FF;
    }
  }
}</style>
